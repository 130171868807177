import React from "react"
import achievementStyles from './a3_achievement.module.css'
import LayoutA3 from "../../components/layout-a3"
import ranking1 from '../../images/a3/ranking1.png'
import ranking2 from '../../images/a3/ranking2.png'
import ranking3 from '../../images/a3/ranking3.png'

export default class A3Achievement extends React.Component {
  render() {
    const color="#7fc527"
    const rankingImgs=[ranking1,ranking2,ranking3]
    return(
      <LayoutA3 >
        <div className={achievementStyles.achievement}>
          <div className={achievementStyles.scanCodeTitle} style={{color:color}}>扫码查看</div>
          <div className={achievementStyles.code}><img alt="hello" src={require("../../images/a2/race_code.png")}></img></div>
          <div className={achievementStyles.scanCodeDesc}>打开微信扫一扫</div>
          <div  className={achievementStyles.toWx}>打开微信查看成绩</div>
          <div className={achievementStyles.rankingContainer}>
            {/*<div className="ranking-icon"><img  alt="hello" src={require(this.state.width<480?'../images/ranking-phone.png':"../images/rankings.png")}/></div>*/}
            <div className={achievementStyles.achievementRanking}>
              {/*{ranking}*/}
              {/*<img alt="hello" src={require('../../images/a2/ranking.png')}/>*/}
              {rankingImgs.map((img,index)=><div className={achievementStyles.rankingImg} key={index}>
                <img src={img} alt="排行榜"/>
              </div>)}
            </div>
          </div>
        </div>
      </LayoutA3>
    )
  }
}